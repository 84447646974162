
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import BodyText from './Typography/BodyText.vue';
	@Component({
		name: 'SliderSwitch',
		components: {
			BodyText,
		},
	})
	export default class SliderSwitch extends Vue {
		@Prop() isToggleOn: boolean;
		@Prop() leftSideText: string;
		@Prop() rightSideText: string;
		@Prop({ default: 'md' }) readonly size: 'sm' | 'md' | 'ms';
		@Prop({ default: 'primary' }) readonly variant: 'primary' | 'secondary' | 'neutral';

		get className() {
			return [`switch-container`, `switch-container--${this.size}`, `switch-container--${this.variant}`];
		}
	}
